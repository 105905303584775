import * as $ from "jquery";
import * as htmx from "htmx.org";
import "bootstrap/js/dist/popover";
import { addEventDelegate } from "./dom";
import { isMob } from "./util";
import { showDialog } from "./dialog";

let bottomModalInitialized = false;

let popoverSelectors = [
    '[data-toggle="popover"]',
    '.tu-popover',
    '.poptitle',
    '.tum-unauthorized-hint',
    '.map-link-tum-popover'
]

/**
 * 
 * @param {HTMLElement} element 
 */
function closeAllPopoversExcept(element) {
    let popovers = [...document.querySelectorAll(popoverSelectors.join(', '))].filter(e => e != element);
    for (let popover of popovers) {
        $(popover).popover('hide');
    }
}

function initPopovers(){
    addEventDelegate(document, 'click', '[data-dismiss=popover]', element => {
        $('.modal.show.opened-as-popover').modal('hide');

        var $popover = $(element).closest('.popover');
        if (!$popover.length) return;
        var $elem = $($popover.data('bs.popover').element);
        $elem.popover('hide');
    });

    $(document).on('shown.bs.popover', function(e){
        let closeBtn = '<a href="javascript:void(0)" data-dismiss="popover" class="fas fa-times tum-black float-right ml-2 tu-popover-close" title="Закрыть"></a>';
        let $elem = $(e.target);
        let $popover = $($elem.data('bs.popover').tip);
        let $header = $popover.find('.popover-header');
        let $body = $popover.find('.popover-body');
        
        htmx?.process($popover[0]);
        
        if ($elem.hasClass('popover-noArrow')) {
            $popover.addClass('noarrow');
        }

        if ($elem.hasClass('popover-addCloseBtn')) {
            let $closeBtnContainer = $header.html() ? $header : $body;

            if ($('.tu-popover-close', $closeBtnContainer).length) return;
            $closeBtnContainer.prepend(closeBtn);
        }
    });

    initTuPopover();
    $('[data-toggle="popover"]').popover({
        sanitize: false
    });

    $(document).on('show.bs.popover', e => {
        if (e.currentTarget?.activeElement) {
            closeAllPopoversExcept(e.currentTarget.activeElement);
        }
        else if (e.target) {
            closeAllPopoversExcept(e.target);
        }

        if (document.querySelector('.dropdown.show')) {
            $('.dropdown.show').dropdown('hide');
        }

        let exceptions = ['.tum-readmore-toggle'];
        if (isMob()) {
            exceptions = exceptions.concat(['.tu-popover', '.tum-unauthorized-hint', '.map-link-tum-popover']);
        } 
        let except = exceptions.join(', ');

        if (e.currentTarget?.activeElement?.matches(except) || e.target?.matches(except)) {
            return false;
        }
    });

    $(document).on('show.bs.dropdown', () => {
        closeAllPopoversExcept(null);
    });
}

/**
 * 
 * @param {HTMLElement | Document | DocumentFragment} [container]
 * @returns 
 */
function _initTuPopover(container = null) {
    if (!container) container = document;
    for (let element of container.querySelectorAll('.poptitle:not([data-original-title])')) {
        $(element).popover({
            html: true,
            trigger: element.classList.contains('hover') ? 'hover' : 'click',
            placement: 'top',
            content: element.title,
            sanitize: false,
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
        });
    }

    if (isMob()) {
        if (!bottomModalInitialized) {
            bottomModalInitialized = true;

            addEventDelegate(document, 'click', ".tum-unauthorized-hint, .map-link-tum-popover, .tu-popover", element => {
                let contentTarget = element.dataset.contenttarget;
                let content = contentTarget ? document.querySelector(contentTarget).innerHTML : element.dataset.content;
                let title = element.title || element.dataset.originalTitle;
                let container = element.dataset.container;
    
                showDialog({
                    content: `<div>${content}</div>`,
                    title,
                    pinToBottom: true,
                    classes: "opened-as-popover",
                    container,
                    modal: element.dataset.modalId
                });
            });
        }

        return;
    }

    for (let element of container.querySelectorAll(".tum-unauthorized-hint, .map-link-tum-popover, .tu-popover")) {
        if ($(element).data('bs.popover')) continue;
        let trigger = element.dataset.trigger;

        let popover = $(element).popover({
            content: function(){
                let target = this.dataset.contenttarget;
                if (target) {
                    let html = document.querySelector(target).innerHTML;
                    return html;
                }

                return this.dataset.content;
            },
            html: true,
            sanitize: false
        });

        if (trigger && trigger != 'hover') continue;

        popover.on('mouseenter', function () {
            var _this = this;
            $(this).popover('show');
            $('.popover').on('mouseleave', function () {
                $(_this).popover('hide');
            });
        }).on('mouseleave', function () {
            var _this = this;
            setTimeout(function () {
                if (!$('.popover:hover').length) {
                    $(_this).popover('hide');
                }
            }, 150);
        });
    }
}

/**
 * 
 * @param {HTMLElement | Document | DocumentFragment | JQuery<HTMLElement>} [container]
 * @returns 
 */
function initTuPopover(container = null){
    if (!container) {
        _initTuPopover();
        return;
    }

    if (container instanceof HTMLElement || container instanceof Document || container instanceof DocumentFragment) {
        _initTuPopover(container);
        return;
    }

    if (typeof(container) == 'string') {
        container = $(container);
    }

    for (let element of container.toArray().filter(e => e instanceof HTMLElement)){
        _initTuPopover(element);
    }
}

/**
 * 
 * @param {HTMLElement} button 
 * @param {() => void} callback 
 */
function onPopoverShown(button, callback){
    $(button).on('shown.bs.popover', callback);
}

/**
 * 
 * @param {HTMLElement} button 
 * @returns {HTMLElement}
 */
function getPopoverElement(button) {
    return $(button).data('bs.popover').tip;
}

function initTooltips(container){
    let except = ['.poptitle', '.notooltip', 'iframe', '.tu-popover', '[data-toggle=popover]'];
    if (isMob()) except.push('.tum-disableMobTitle');
    $('[title]', container || document).not(except.join(', ')).tooltip({
        placement: 'auto',
        boundary: 'window',
        html: true,
        trigger: 'hover'
    });

    addEventDelegate(document, 'mouseover', '[title]:not([data-original-title])', function(element){
        $(element).not(except.join(', ')).tooltip({
            placement: 'auto',
            boundary: 'window',
            html: true,
            trigger: 'hover'
        });

        $(element).trigger('mouseover');
    });

    addEventDelegate(document, 'click', '[title]', function(element){
        $(element).tooltip('hide');
    });
}

export { initPopovers, initTuPopover, onPopoverShown, getPopoverElement, closeAllPopoversExcept, initTooltips };
